import React from 'react'

const Container = ({children}) => {
	return (
		<div className='w-[95%] max-w-[1400px] mx-auto relative'>
			{children}
		</div>
	)
}

export default Container